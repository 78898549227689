/* header css */

.no-modal-buttons .ant-modal-footer {
    display: none;
}

.ant-modal-title {
    display: flex;
    justify-content: center;
    font-size: x-large !important;
}

.ant-modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.fit-content {
    width: fit-content;
}

.base-page {
    min-height: 100vh;
}
/* footer{
    height: 110px;
} */
.main-area {
    /* max-height: calc(100vh - 180px); */
    /* overflow-y: scroll; */
}

.ant-form-item {
    margin-bottom: unset !important;
}

.ant-upload-drag:hover{
    border-color: #298752 !important;
}
.border-radius-icon{
    border-radius: 100%;
}

.ant-tabs-ink-bar {
    height: 5px;
    background: transparent;
}

.no-lb {
    white-space:nowrap;
}


.ant-btn-primary {
    color: #fff;
    background-color:#298752 !important;
      box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  }

.cursor-hand {
    cursor: grab;
}