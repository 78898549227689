@import url("./organization.css");
@import url("./layout.css"); /* tailwind configurations */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");
html{
  scroll-behavior: smooth;
}

:root {
  --primary-color: #298752;
  --secondary-color: white;
  --background-color: #F9F9F9;
}

.primary-border {
  border-color: var(--primary-color);
}

.primary-border-opaque {
  border-color: rgba(var(--primary-color), 0.1);
}

.primary-text {
  color: var(--primary-color);
}

.primary-fill {
  fill: var(--primary-color);
}

.primary-main {
  background-color: var(--primary-color);
}

.primary-main-hover {
  color: #4F4C4C;
}

.primary-main-hover:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color)
}

.ring-primary-main {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0, 0, 0 / var(--tw-ring-opacity));
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
  text-shadow: 0 0 0.25px var(--primary-color);
}

.ant-tabs .ant-tabs-tab:hover {
  color: var(--primary-color) !important;
}

.ant-tabs-ink-bar::after {
  content: " ";
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  height: 5px;
  background: var(--primary-color) !important;
  width: 100%;
  transform: translateX(-50%);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner span {
  text-transform: capitalize;
}

.secondary-main {
  background-color: var(--secondary-color);
}

.secondary-text {
  color: var(--secondary-color);
}

.background-main {
  background-color: var(--background-color);
}


/*body {*/
/*  margin: 0;*/
/*  font-family: 'Source',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  background-color: #F0F5F5;*/
/*}*/

/* css files imports */
/*@import url("./homepage.css");*/
/*@import url("./modal.css");*/
/*@import url("./loader.css");*/

