#bar h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

#bar h2 span {
  background:rgb(240 245 245 / var(--tw-bg-opacity));
  padding:0 10px;
}
