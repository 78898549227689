.mixin {
  .title {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .title > p {
    display: flex;
    align-items: center;
  }
  
  .title > p > .status {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .title > p > .status.green {
    background-color: #38ab30;
  }
  
  .title > p > .status.orange {
    background-color: #fab600;
  }
  
  .title > p > .status.red {
    background-color: #f20f36;
  }
  
  .title > p > .sub-title {
    flex: 1;
  }
}

.card {
  margin-left: 0.5cm;
  -webkit-print-color-adjust: exact;
  -webkit-filter: blur(0);
}

body hr {
  display: none;
  border: 0;
  border-top: 1px solid #e6f5fc;
  width: 100%;
}

body #pdf-dom .intro {
  margin-top: 0 !important;
}

body .pdf-container {
  display: block;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  max-width: 100vw;
  width: 100vw;
}

body .pdf-container header.header .content-container > a,
body .pdf-container header.header .content-container > span {
  white-space: nowrap;
}

body .pdf-container header.header .content-container > span {
  right: 0;
  position: absolute;
  margin: 16px 16px 14px;
  line-height: 32px;
}

body .pdf-container .pdf-content-container > * {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

body .pdf-container .pdf-content-container #pdf-card-overview {
  margin-bottom: 20px;
}

body .pdf-container .pdf-content-container > hr {
  display: block;
  border: 0;
  border-top: 1px solid #e6f5fc;
  width: 100%;
}

body .pdf-container .pdf-content-container .improvement {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 300px 1fr;
  column-gap: 40px;
  margin-bottom: 40px;
  margin-left: 20px;
}

body .pdf-container .pdf-content-container .improvement::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  bottom: -25px;
  background: #e6f5fc;
}

body .pdf-container .pdf-content-container .improvement .card {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  padding: 20px;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

body .pdf-container .pdf-content-container .improvement .card figure {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  margin-left: auto;
}

body .pdf-container .pdf-content-container .improvement .card .description {
  grid-column: 1/3;
}

body .pdf-container .pdf-content-container .improvement .modal-content {
  display: flex;
  padding: 0;
  border-radius: 0.5rem;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

body .pdf-container .pdf-content-container .improvement .modal-content .title {
  padding: 20px;
}

body .pdf-container .pdf-content-container .improvement .modal-content .title .sub-title {
  overflow: visible;
}

body .pdf-container .pdf-content-container .improvement .modal-content .calculations {
  padding: 20px;
  background: rgba(0, 157, 224, 0.06);
}

body .pdf-container .pdf-content-container .highlight--left h2 {
  width: 50%;
  margin: 0 auto;
}

body .pdf-container .pdf-content-container .highlight--left .highlight__inner::before {
  left: 12.5%;
}

body .pdf-container .pdf-content-container .contact-details-column {
  width: 50%;
  margin: 0 auto;
}

body strong {
  white-space: nowrap;
}

body footer * {
  box-shadow: none;
  padding: 0;
  margin: 0;
}

body footer .content-container {
  padding: 0 !important;
  display: flex;
  background: white;
}

body footer .content-container .footer--column {
  flex: 1;
  display: flex;
  background: white;
}

body footer .content-container .footer--column.text-container {
  margin-top: 0 !important;
  margin-right: 5rem;
}

body footer .content-container .footer--column h2 {
  line-height: 60px;
}

.no-after::after {
  content: "";
  height: 0px !important;
  bottom: 0px !important;
  background: none !important;
}

.detail-layout > div {
}

.heat-loss-title {
  max-width: 100%;
  padding-top: 10px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.heat-loss-title:before,
.heat-loss-title:after {
  position: absolute;
  top: 60%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: gray;
}

.heat-loss-title:before {
  margin-left: -50.5%;
  text-align: right;
}

.heat-loss-title:after {
  margin-left: 5px;
  text-align: right;
}

#pdfcustomA {
  content: "";
}