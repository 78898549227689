div.org_container {
    display: flex;
    flex-wrap: wrap;
}

div.org_child {
    flex: calc(33% - 42px);
    box-shadow: 1px 1px 5px 2px rgb(217 217 217 / 32%);
}

.card_link {
    text-decoration: underline;
    cursor: pointer;
}

.card_status {
    text-transform: uppercase;
    color: #acacac;
}
