
.active, .active > *{
  visibility: visible;
}
.inactive{
visibility: hidden;
}


.span-value {
  position: relative;
  transform: translate(50%, 0);
  white-space: nowrap;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000000;
    top: 50%;
    left: calc(50% - 5px);
  }
}

.span-value-update {
  position: relative;
  transform: translate(50%, 0);
  white-space: nowrap;

 
}

.span-value-update::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid lightgray;
  top: 50%;
  left: calc(50% - 5px);
}
.epc-label {
  width: 100%;
  height: 20px;

}
.epc-label span{
  display: flex;
  justify-content: flex-end;

}
